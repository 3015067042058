.root {
  @apply cursor-pointer px-8 py-3 leading-6 text-center text-lg text-inherit
  flex justify-center items-center tracking-wide rounded-brand outline-none;
}

.brand {
  @apply bg-brand text-white font-semibold;

  &:hover {
    transition: all 150ms cubic-bezier(0.4, 0, 1, 1) 0s;
  }
  &:hover,
  &:focus {
    @apply bg-brand-hover;
  }

  &:active {
    @apply bg-brand-active;
  }
}

.black {
  @apply bg-black text-white font-semibold;

  &:hover,
  &:focus {
    @apply bg-grayText outline-none;
  }

  &:active {
    @apply bg-base-border;
  }
}

.outlined {
  @apply font-secondary font-semibold text-grayText transition-all bg-transparent ring-2 ring-inset ring-grayText;

  &:hover,
  &:focus {
    @apply ring-black text-black bg-transparent;
  }

  &:active {
    @apply ring-grayText text-white bg-grayText;
  }
}

.outlinedBrand {
  @apply font-secondary font-semibold text-brand transition-all bg-white ring-1 ring-inset ring-brand;

  &:hover,
  &:focus {
    @apply ring-brand-hover text-brand-hover;
  }

  &:active {
    @apply ring-brand-active text-white bg-brand-active;
  }
}

.link {
  @apply text-sm leading-6 tracking-tight bg-transparent border-none shadow-none outline-none inline-block py-0 px-0;

  &:hover,
  &:focus {
    @apply underline;
  }
}

.brandLink {
  @apply bg-transparent border-none shadow-none outline-none inline-block py-0 px-0 text-sm text-brand;

  &:hover,
  &:focus {
    @apply underline;
  }
}

.clear {
  @apply text-sm bg-transparent font-medium px-0 underline capitalize py-1.5 border-0 transition-all duration-150 ease-out shadow-none;
  color: black;

  &:hover {
    @apply bg-transparent underline font-semibold border-0;
    color: var(--brand);
  }
  &.disabled {
    background-color: initial !important;
  }
}

.inverted {
  @apply text-sm bg-white text-brand px-6;
  padding-block: 9px;

  &:hover,
  &:focus {
    @apply bg-grayBg shadow-btn-hover;
  }

  &:active {
    @apply bg-base-border;
  }
}

.loading {
  @apply bg-neutral-50 cursor-not-allowed;
  color: #999999;
  border-color: #eaeaea;
}

.big {
  @apply px-10 py-5 text-2xl tracking-normal;
}

.medium {
  @apply px-8 text-lg leading-6 tracking-normal;
  padding-block: 15px;
}

.disabled,
.disabled:hover {
  @apply border-base-border bg-base-border cursor-not-allowed;
  color: #888888;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
